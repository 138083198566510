@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./elements.css";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;700;800&display=swap');

@import "./components/section-header.css";
@import "./components/service-card.css";
@import "./components/splide-overrides.css";
@import "./components/projects.css";
