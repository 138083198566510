.projects {
}
.projects__project {
}
.projects__project-title {
    @apply uppercase tracking-wider font-bold text-lg;
}
.projects__project-description {
    @apply flex-1 py-4;
}
.projects__project-description p {
    @apply pb-4;
}

.projects__project-image {
    @apply h-full w-full md:w-1/2 py-4 md:p-4 md:pl-8 object-cover max-h-96;
}
.projects__project-image img {
    @apply object-cover w-full h-auto max-h-96;
}

.projects__nav {
    @apply flex flex-wrap justify-center;
}

.projects__nav-item {
    @apply w-1/2 md:w-1/3 lg:w-1/4 relative h-44 overflow-hidden border-transparent border-4;
}
.projects__nav-item:focus {
    @apply outline-none;
}
.projects__nav-image {
    @apply flex h-full w-full;
}
.projects__nav-image img{
    @apply object-cover h-full w-full;
}
.projects__nav-title {
    @apply flex items-center justify-center absolute bottom-0 left-0 w-full h-full p-4 text-white text-center uppercase text-base md:text-lg z-10;
    @apply bg-primary bg-opacity-50 transition-all duration-500 ease-in-out transform-gpu;
}
.projects__nav-item:hover .projects__nav-title {
    @apply bg-opacity-80 scale-110;
}
.projects__nav-item.active {
    @apply opacity-60 pointer-events-none;
}
.projects__nav-item.active .projects__nav-title {
    @apply opacity-0;
}